.form-signin {
	width: 100%;
	max-width: 330px;
	padding: 15px;
	margin: 0 auto;
}
.form-signin .checkbox {
	font-weight: 400;
}
.form-signin .form-control {
	position: relative;
	box-sizing: border-box;
	height: auto;
	padding: 10px;
	font-size: 16px;
}
.form-signin .form-control:focus {
	z-index: 2;
}
.form-signin input[type='email'] {
	margin-bottom: -1px;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}
.form-signin input[type='password'] {
	margin-bottom: 10px;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}
.bSignInForm {
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 40px;
	padding-bottom: 40px;
	background-color: #f5f5f5;
	min-height: 100vh;
}
.bSpinner {
	height: 100vh;
	align-items: center;
}

.table th {
	vertical-align: top;
}
.table td {
	vertical-align: middle;
}

.table pre {
	white-space: pre-wrap;
}
.table .photo {
	max-width: 196px;
}
.table .id {
	max-width: 150px;
}
.table .createDate,
.table .modifiedDate {
	width: 130px;
}
.bSlideItem {
	max-width: 300px;
}
.bLogoTG {
	max-width: 180px;
	width: 100%;
}
.slick-slider {
	user-select: auto !important;
}
.slick-arrow {
	visibility: hidden !important;
}
.bCopy {
	cursor: pointer;
}
